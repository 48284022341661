import {
  FINANCE_CONTACT_TYPES,
  FINANCE_DEALS_STAGES,
  FINANCE_DEALS_STATUSES,
  PARTNER_TYPES,
  PERMISSIONS,
  TAGS,
} from '~/constants'
import { useAuthStore } from '~/store/auth'
import type { Deal } from '~/types'

export default (dealRefOrGetter: MaybeRefOrGetter<Deal | undefined>) => {
  const authStore = useAuthStore()

  const deal = computed(() => toValue(dealRefOrGetter))

  const isNewDealStage = computed(() => {
    return deal.value?.stage?.code === FINANCE_DEALS_STAGES.NEW_DEAL
  })

  const isClosedDealStage = computed(() => {
    return deal.value?.stage?.code === FINANCE_DEALS_STAGES.CLOSED
  })

  const isCEOApprovalStatus = computed(() => {
    return deal.value?.status?.code === FINANCE_DEALS_STATUSES.NEW_DEAL_CEO_APPROVAL
  })

  const isReferralDeal = computed(() => {
    return deal.value?.tags.some(({ code }) => code === TAGS.REFERRAL)
  })

  const primaryContact = computed(() => {
    return deal.value?.contacts.find((c) => c.type.code === FINANCE_CONTACT_TYPES.PRIMARY)
  })

  const assignedPartner = computed(() => {
    return deal.value?.partners?.find(
      ({ partner_type: partnerType, partner }) => partner.manager && partnerType.code === PARTNER_TYPES.ASSIGNED
    )?.partner
  })

  const canUpdateFields = computed(() => {
    if (useHasPermissions([PERMISSIONS.CAN_EDIT_DEAL_ON_ANY_STAGE])) return true

    return (
      [FINANCE_DEALS_STATUSES.NEW_DEAL_NEW, FINANCE_DEALS_STATUSES.NEW_DEAL_SM_APPROVAL].includes(
        deal.value?.status?.code!
      ) && useHasPermissions([PERMISSIONS.EDIT_DEAL])
    )
  })

  const canEditOnlyBookingDateOnTheDeal = computed(() => {
    if (useHasPermissions([PERMISSIONS.CAN_EDIT_ONLY_BOOKING_DATE_ON_THE_DEAL])) return true

    return canUpdateFields.value
  })

  const canSendForSMApproval = computed(() => {
    return (
      useHasPermissions([PERMISSIONS.REQUEST_DEAL_INFO_APPROVAL]) &&
      deal.value?.status?.code === FINANCE_DEALS_STATUSES.NEW_DEAL_NEW
    )
  })

  const canApproveNewDeal = computed(
    () =>
      (authStore.getIsAdmin ||
        useHasPermissions([PERMISSIONS.APPROVE_DEAL_INFO, PERMISSIONS.DEAL_INFO_AND_COMMISSIONS_APPROVAL], true)) &&
      deal.value?.status?.code === FINANCE_DEALS_STATUSES.NEW_DEAL_SM_APPROVAL
  )

  const canCancelDeal = computed(() => {
    if (
      [FINANCE_DEALS_STATUSES.DOWN_PAYMENT_PARTIAL, FINANCE_DEALS_STATUSES.DOWN_PAYMENT_RECEIVED].includes(
        deal.value?.status.code!
      )
    ) {
      return useHasPermissions([PERMISSIONS.CANCEL_DEAL_WHEN_DOWN_PAYMENT_IS_RECEIVED])
    }

    if (
      [FINANCE_DEALS_STAGES.NEW_DEAL, FINANCE_DEALS_STATUSES.DOWN_PAYMENT_PENDING].includes(
        deal.value?.stage.code || ''
      )
    ) {
      return useHasPermissions([PERMISSIONS.EDIT_DEAL])
    }

    return false
  })

  const canAddTags = computed(
    () =>
      [FINANCE_DEALS_STAGES.NEW_DEAL, FINANCE_DEALS_STAGES.FINANCE_REVIEW].includes(deal.value?.stage.code || '') &&
      useHasPermissions([PERMISSIONS.EDIT_DEAL_TAGS])
  )

  const isCommissionsEditable = computed(() => {
    return [FINANCE_DEALS_STAGES.NEW_DEAL, FINANCE_DEALS_STAGES.FINANCE_REVIEW].includes(deal.value?.stage.code || '')
  })

  return {
    isNewDealStage,
    isClosedDealStage,
    isCEOApprovalStatus,
    isReferralDeal,
    primaryContact,
    assignedPartner,
    canUpdateFields,
    canEditOnlyBookingDateOnTheDeal,
    canSendForSMApproval,
    canApproveNewDeal,
    canCancelDeal,
    canAddTags,
    isCommissionsEditable,
  }
}
